// src/components/Services.js
import React, { useState, lazy, Suspense } from 'react';
import { motion } from 'framer-motion';

const CarRentalForm = lazy(() => import('./CarRentalForm'));
const HotelForm = lazy(() => import('./HotelForm'));
const TravelInsuranceForm = lazy(() => import('./TravelInsuranceForm'));
const VisaForm = lazy(() => import('./VisaForm'));
const ToursForm = lazy(() => import('./ToursForm'));
const StudyAboard = lazy(() => import('./StudyAboard'));
const ScholarshipForm = lazy(() => import('./ScholarshipForm')); // Import ScholarshipForm

const Services = () => {
  const [activeForm, setActiveForm] = useState('study');

  return (
    <div className="py-8 bg-white">
      <motion.h2
        className="text-3xl font-bold text-center mb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Services
      </motion.h2>
      <div className="flex justify-center mb-6 flex-wrap">
        <button
          onClick={() => setActiveForm('study')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'study' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'study' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'study'}
          aria-label="Study"
        >
          Study Aboard
        </button>
        <button
          onClick={() => setActiveForm('car')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'car' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'car' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'car'}
          aria-label="Car Rental"
        >
          Car Rental
        </button>
        <button
          onClick={() => setActiveForm('hotel')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'hotel' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'hotel' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'hotel'}
          aria-label="Hotel"
        >
          Hotel
        </button>
        <button
          onClick={() => setActiveForm('insurance')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'insurance' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'insurance' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'insurance'}
          aria-label="Travel Insurance"
        >
          Travel Insurance
        </button>
        <button
          onClick={() => setActiveForm('visa')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'visa' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'visa' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'visa'}
          aria-label="Visa"
        >
          Visa
        </button>
        <button
          onClick={() => setActiveForm('tours')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'tours' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'tours' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'tours'}
          aria-label="Tours"
        >
          Tours
        </button>
        <button
          onClick={() => setActiveForm('scholarship')}
          className={`mx-2 px-4 py-2 rounded-full border ${activeForm === 'scholarship' ? 'border-blue-500' : 'border-gray-300'} ${activeForm === 'scholarship' ? 'bg-blue-500 text-white' : 'bg-transparent text-blue-500'} transition-colors`}
          aria-pressed={activeForm === 'scholarship'}
          aria-label="Scholarship"
        >
          Scholarship
        </button>
      </div>
      <div className="flex justify-center w-full">
        <Suspense fallback={<div>Loading...</div>}>
          {activeForm === 'study' && <StudyAboard />}
          {activeForm === 'car' && <CarRentalForm />}
          {activeForm === 'hotel' && <HotelForm />}
          {activeForm === 'insurance' && <TravelInsuranceForm />}
          {activeForm === 'visa' && <VisaForm />}
          {activeForm === 'tours' && <ToursForm />}
          {activeForm === 'scholarship' && <ScholarshipForm />} {/* Add ScholarshipForm */}
        </Suspense>
      </div>
    </div>
  );
};

export default Services;
