import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import posterImage from '../assets/Img/Digital_Marketing.png'; // Update with your image path

const FullPagePoster = ({ onClose }) => {
  const navigate = useNavigate();

  const handleEnrollNow = () => {
    navigate('/course-summary');
    onClose();
  };

  const handleViewDetails = () => {
    navigate('/course-details');
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50">
      <motion.div
        className="relative bg-cover bg-center w-11/12 md:w-1/2 lg:w-1/3 h-4/5 md:h-4/5 lg:h-4/5 shadow-lg rounded-lg"
        style={{ backgroundImage: `url(${posterImage})` }}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white bg-transparent hover:bg-red-500 transition-all p-2 rounded-full z-50"
        >
          ✕
        </button>
        <div className="relative z-20 flex flex-col items-center justify-end h-full text-center text-white px-6 pb-8">
          <motion.div
            className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 1 }}
          >
            <button
              className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-transparent hover:border-2 hover:border-green-500 hover:text-green-500 transition"
              onClick={handleEnrollNow}
            >
              Enroll Now
            </button>
            <button
              className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-transparent hover:border-2 hover:border-blue-500 hover:text-blue-500 transition"
              onClick={handleViewDetails}
            >
              View Details
            </button>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default FullPagePoster;
