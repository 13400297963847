// src/components/StudyAboard.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const countries = ["USA", "UK", "Canada", "Australia", "Germany"];

const FlightForm = () => {
  const [currentCountry, setCurrentCountry] = useState(countries[0]);
  const [showModal, setShowModal] = useState(false);
  const [formStep, setFormStep] = useState(1); // State to track which form step is displayed
  const [applicationSubmitted, setApplicationSubmitted] = useState(false); // New state for submission status

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [nationality, setNationality] = useState("");

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentCountry((prevCountry) => {
        const currentIndex = countries.indexOf(prevCountry);
        const nextIndex = (currentIndex + 1) % countries.length;
        return countries[nextIndex];
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormStep(1); // Reset the form step when the modal is closed
    setFormErrors({});
    setApplicationSubmitted(false); // Reset submission status
  };

  const handleNextStep = () => {
    if (validateStep1()) {
      setFormStep(2);
    }
  };

  const handlePreviousStep = () => {
    setFormStep(1);
  };

  const validateStep1 = () => {
    const errors = {};
    if (!fullName) errors.fullName = "Full name is required";
    if (!email) errors.email = "Email address is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep2 = () => {
    const errors = {};
    if (!whatsApp) errors.whatsApp = "WhatsApp number is required";
    if (!educationLevel) errors.educationLevel = "Education level is required";
    if (!nationality) errors.nationality = "Nationality is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (validateStep2()) {
      const formData = {
        fullName,
        email,
        whatsApp,
        educationLevel,
        nationality
      };
  
      // Replace this URL with your actual Google Apps Script web app URL
      const scriptURL = 'https://script.google.com/macros/s/AKfycbzgNn6lElCG2z5NsTjXqqzeuJI_FohATxzyxeuJnR3d9Qm_EQZwBUxjvSkS6KAHv33Dcg/exec';
  
      fetch(scriptURL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData)
      })
      .then(() => {
        setApplicationSubmitted(true); // Show confirmation message on success
      })
      .catch((error) => {
        console.error('Error!', error.message);
      });
    }
  };
  

  return (
    <>
      <motion.div
        className="w-full md:w-4/5 mx-auto p-4 bg-white shadow-lg rounded-lg cursor-pointer"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        onClick={handleClick}
      >
        <h1 className="text-3xl md:text-5xl font-bold text-center">
          Want To Study In <span className="text-gold">{currentCountry}</span>
        </h1>
      </motion.div>

      {/* Modal for the student application form */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
            <h2 className="text-xl font-bold mb-4">Student Application Form</h2>
            <form onSubmit={handleSubmit}>
              {applicationSubmitted ? (
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <h3 className="text-2xl font-bold mb-4">
                    Your application has been sent successfully!
                  </h3>
                  <p className="mb-4">
                    An admission advisor will contact you soon.
                  </p>
                  <motion.div
                    className="w-16 h-16 mx-auto bg-[#FFD700] rounded-full flex items-center justify-center"
                    animate={{ rotate: [0, 360] }}
                    transition={{ duration: 1, repeat: Infinity }}
                  >
                    <span className="text-white text-2xl">✔️</span>
                  </motion.div>
                  <div className="flex justify-center mt-6">
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                    >
                      Close
                    </button>
                  </div>
                </motion.div>
              ) : (
                <>
                  {formStep === 1 && (
                    <motion.div
                      key="step1"
                      initial={{ opacity: 0, x: -100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 100 }}
                    >
                      {/* First part of the form */}
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Full Name
                        </label>
                        <input
                          type="text"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          placeholder="Enter your full name"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formErrors.fullName && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.fullName}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Email Address
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter your email address"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formErrors.email && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.email}
                          </p>
                        )}
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                          onClick={handleNextStep}
                        >
                          Next
                        </button>
                      </div>
                    </motion.div>
                  )}

                  {formStep === 2 && (
                    <motion.div
                      key="step2"
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -100 }}
                    >
                      {/* Second part of the form */}
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          WhatsApp Number
                        </label>
                        <input
                          type="tel"
                          value={whatsApp}
                          onChange={(e) => setWhatsApp(e.target.value)}
                          placeholder="Enter your WhatsApp number"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formErrors.whatsApp && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.whatsApp}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Highest Level of Education
                        </label>
                        <select
                          value={educationLevel}
                          onChange={(e) => setEducationLevel(e.target.value)}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="">Select your education level</option>
                          <option value="highschool">High School</option>
                          <option value="bachelor">Bachelor's Degree</option>
                          <option value="masters">Master's Degree</option>
                          <option value="phd">PhD</option>
                        </select>
                        {formErrors.educationLevel && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.educationLevel}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Nationality
                        </label>
                        <input
                          type="text"
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                          placeholder="Enter your nationality"
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formErrors.nationality && (
                          <p className="text-red-500 text-xs italic">
                            {formErrors.nationality}
                          </p>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <button
                          type="button"
                          className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
                          onClick={handlePreviousStep}
                        >
                          Previous
                        </button>
                        <button
                          type="submit"
                          className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                        >
                          Submit
                        </button>
                      </div>
                    </motion.div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FlightForm;
